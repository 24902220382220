@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  font-size: 0.9rem;
  padding: 12px 12px;
  border-radius: 8px;
  background-color: #353b4a;
  color: white;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  text-transform: uppercase;
  border: 3px solid #353b4a;

  &:hover {
    background-color: #e01313;
    border-color: #e01313;
    color: white;
  }

  &_block {
    display: block;
    width: 100%;
  }

  &_outlined:not(:hover) {
    background-color: transparent;
    color: #ffffff;
  }

  &_void {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #353b4a;

    &:hover {
      color: #e01313;
    }
  }

  &_danger {
    background-color: #e01313 !important;
    border-color: #e01313 !important;

    &:hover {
      background-color: rgb(239, 45, 45) !important;
      border-color: rgb(239, 45, 45) !important;
    }
  }

  &_round {
    border-radius: 20px;
  }

  &_spinning {
    svg {
      animation-name: spin;
      animation-duration: 3000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      display: block;
      margin: 0 auto;
    }
  }
}

.select {
  margin-bottom: 15px;

  label {
    width: 100%;
    display: block;
    margin-bottom: 5px;
  }
}

.input {
  margin-bottom: 15px;

  input {
    width: 100%;
    display: block;
    font-size: 1em;
    padding: 12px 10px;
    border: 1px solid rgb(201, 201, 201);
  }

  label {
    width: 100%;
    display: block;
    margin-bottom: 5px;
  }
}

.textarea {
  color: black;

  &.textarea_autosizeable {
    textarea {
      resize: none;
      overflow: hidden;
    }
  }
}

.form {
  display: block;

  .form__validationError {
    color: rgb(228, 43, 43);
    margin-top: 5px;
    font-size: 0.9em;
  }
}
