@import "../variables";

.main {
  padding: 20px;
  min-height: 100%;

  &__container {
    max-width: $max-main-width;
    min-height: 100%;
    margin: 0 auto;

    @media all and (max-width: $max-main-width) {
      max-width: $container-max-width;
    }
  }
}

.container {
  display: flex;

  @media all and (max-width: $max-main-width) {
    display: block;
  }

  &__left-sidebar {
    min-height: 100%;

    @if $right-sidebar-width > $left-sidebar-width {
      flex: 0 1 max($left-sidebar-width, $right-sidebar-width);
      padding-left: $right-sidebar-width - $left-sidebar-width;

      @media all and (max-width: $max-main-width) {
        padding-left: 0px;
        padding-bottom: 20px;
      }
    } @else {
      flex: 0 1 $left-sidebar-width;
    }
  }

  &__content {
    @media all and (min-width: calc($max-main-width + 1px)) {
      padding-left: 40px;
    }

    min-height: 100%;
    flex: 1 0 100%;
    max-width: $container-max-width;
    z-index: 0;
  }

  &__right-sidebar {
    min-height: 100%;
    flex: 0 1 $right-sidebar-width;
    padding-left: 20px;
    padding-right: 20px;
  }
}
