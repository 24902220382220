.user {
  position: relative;
  z-index: 100000;

  &__active {
    .user__menu {
      display: block;
    }
  }

  &__icon {
    position: relative;
    width: 60px;
    height: 60px;
    display: inline-block;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;

    @media all and (max-width: 350px) {
      width: 40px;
      height: 40px;
    }

    &--active {
      border: 3px solid rgb(231, 97, 97);
    }

    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
  }

  &__menu {
    overflow: hidden;
    display: none;
    width: 150px;
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;
    right: 0px;
    top: 70px;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.06);
    background-color: #41434e;
    border-radius: 8px;
    z-index: 100;
    text-align: left;
  }
  
 &__menu-link {
    display: block;
    font-size: 0.9em;
    padding: 10px 20px;

    &:hover {
      color: #ffffff;
      background-color: #ac1f1f;
    }
  }
}