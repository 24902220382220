@import "../variables";

.menu {
  @media all and (max-width: ($max-main-width)) {
    position: static;
    display: flex;
    flex-wrap: wrap;
  }

  list-style-type: none;
  padding: 0px;
  margin: 0px;

  &__title {
    font-size: 90%;
    color: #7b7b7b;

    @media all and (max-width: ($max-main-width)) {
      display: none;
    }
  }

  &__item {

    @media all and (max-width: ($max-main-width)) {
      padding: 0px 5px;
    }

    &_disabled {
      .menu__link {
        background-color: transparent;
        color: #7b7b7b;
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }

  &__delimiter {
    height: 40px;

    @media all and (max-width: ($max-main-width)) {
      height: auto;
      width: 20px;
    }
  }

  &__link {
    background-color: transparent;
    padding: 5px 0px;
    display: block;
    color: #fff;

    &_icon {
      padding: 3px 0px;
    }
  }
}

.sidebar {
  position: sticky;
  top: 20px;
  text-align: right;

  &__logo {
    margin-bottom: 80px;

    @media all and (max-width: ($max-main-width)) {
      margin-bottom: 0;
      margin-right: 15px;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  @media all and (max-width: ($max-main-width)) {
    position: static;
    text-align: left;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }

  &__menu {
    @media all and (max-width: ($max-main-width)) {
      margin-left: auto;
      margin-right: 20px;
    }

    @media all and (min-width: ($max-main-width + 1px)) {
      margin-bottom: 50px;
    }
  }

  &__contacts {
    @media all and (max-width: ($max-main-width)) {
      margin-right: 10px !important;
    }

    @media all and (min-width: ($max-main-width + 1px)) {
      margin-bottom: 50px !important;
    }
  }
}

.contacts {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;

  @media all and (max-width: 465px) {
    flex-direction: column;
  }

  &__item {
   
      @media all and (max-width: 465px) {
        &--hide-on-mobile {
          display: none;
        }
      }
   
    
    &:not(:last-child) {
      margin-right: 3px;
    }

    svg {
      height: 1.6rem !important;
      width: 1.6rem !important;
    }

    @media all and (max-width: 430px) {
      svg {
        height: 1.4rem !important;
        width: 1.4rem !important;
      }
    }
  }
}

.languages {
  list-style: none;
  padding: 0;
  margin: 0;
  vertical-align: 17px;
  font-size: .8rem;

  @media all and (min-width: ($max-main-width + 1px)) {
    display: flex;
    justify-content: flex-end;
  }

  &__item {
    @media all and (min-width: ($max-main-width + 1px)) {
      &:not(:last-child) {
        margin-right: 5px;
      }
    }

    &_active {
      color: var(--accent-color);
    }
  }
}
